<script>
import { mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { useAlert } from 'dashboard/composables';
import { required, maxLength } from '@vuelidate/validators';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader.vue';

// checks value matches a simple hostname regex
const HOSTNAME = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i;
const hostname = value => HOSTNAME.test(value);
const WS_URL = /^ws(s)?:\/\/.*/i;
const wsUrl = value => WS_URL.test(value);
export default {
  components: {
    PageHeader,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      sipHost: '',
      wsUrl: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
  },
  validations: {
    sipHost: { required, maxLen: maxLength(2048), hostname },
    wsUrl: { required, maxLen: maxLength(2048), wsUrl },
  },
  methods: {
    async createChannel() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      try {
        const phoneChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            channel: {
              type: 'phone',
              sip_host: this.sipHost,
              ws_url: this.wsUrl,
            },
          }
        );

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: phoneChannel.id,
          },
        });
      } catch (error) {
        useAlert(
          error.message ||
            this.$t('INBOX_MGMT.ADD.PHONE_CHANNEL.API.ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>

<template>
  <div
    class="border border-slate-25 dark:border-slate-800/60 bg-white dark:bg-slate-900 h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0"
  >
    <PageHeader
      :header-title="$t('INBOX_MGMT.ADD.PHONE_CHANNEL.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.PHONE_CHANNEL.DESC')"
    />
    <form class="flex flex-wrap mx-0" @submit.prevent="createChannel()">
      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: v$.sipHost.$error }">
          {{ $t('INBOX_MGMT.ADD.PHONE_CHANNEL.SIP_HOST.LABEL') }}
          <input
            v-model.trim="sipHost"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.PHONE_CHANNEL.SIP_HOST.PLACEHOLDER')
            "
            @input="v$.sipHost.$touch"
          />
        </label>
        <p class="help-text">
          {{ $t('INBOX_MGMT.ADD.PHONE_CHANNEL.SIP_HOST.SUBTITLE') }}
        </p>
      </div>
      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: v$.wsUrl.$error }">
          {{ $t('INBOX_MGMT.ADD.PHONE_CHANNEL.WS_URL.LABEL') }}
          <input
            v-model.trim="wsUrl"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.PHONE_CHANNEL.WS_URL.PLACEHOLDER')"
            @input="v$.wsUrl.$touch"
          />
        </label>
        <p class="help-text">
          {{ $t('INBOX_MGMT.ADD.PHONE_CHANNEL.WS_URL.SUBTITLE') }}
        </p>
      </div>

      <div class="w-full">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :button-text="$t('INBOX_MGMT.ADD.PHONE_CHANNEL.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>
