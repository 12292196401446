<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PhoneControls',
  computed: {
    ...mapGetters({
      streams: 'phone/getStreams',
      silent: 'phone/getSpeakerSilent',
    }),
    remoteElement() {
      return document.getElementById('phoneRemoteAudio');
    },
    localElement() {
      return document.getElementById('phoneLocalAudio');
    },
  },
  watch: {
    streams(newVal) {
      if (newVal.remote) {
        this.remoteElement.srcObject = newVal.remote;
      }
      if (newVal.local) {
        this.localElement.srcObject = newVal.local;
      }
    },
  },
};
</script>

<template>
  <audio id="phoneRemoteAudio" autoplay :muted="silent" />
  <audio id="phoneLocalAudio" muted />
</template>
