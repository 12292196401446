/* global axios */
import ApiClient from './ApiClient';

class UserPhoneCredentials extends ApiClient {
  constructor() {
    super('phone_credentials', { accountScoped: true });
  }

  update(params) {
    return axios.patch(`${this.url}`, params);
  }
}

export default new UserPhoneCredentials();
